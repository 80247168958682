import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {LanguageService} from "src/app/services/language/language.service"
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'demianolmedo';
  
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translateService: TranslateService,
    private location: Location,
    private languageService: LanguageService
    ){
    }
  ngOnInit(): void{
    
    this.languageService.initLanguage()


    this.titleService.setTitle( "ELIZIA | Asistente Virtual" );

    this.metaService.addTags([
      {name: 'keywords', content: 'desarrollo web, aplicaciones web, automatización de procesos, control de inventarios, chatbots, servicios tecnológicos, pequeñas empresas, medianas empresas'},
      {name: 'description', content: 'Automatiza y optimiza tu negocio con aplicaciones web personalizadas. Ofrecemos soluciones tecnológicas para emprendedores y pymes que buscan mejorar su productividad, reducir costos y aumentar su presencia en línea. Contacta a Demian Olmedo, experto desarrollador fullstack, y transforma la experiencia de tu empresa y clientes.'},
    ]);
    
    
    AOS.init(); 

  }
}
