import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';

import {trigger, state, style, animate, transition, stagger, query } from "@angular/animations"
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('bannerTrigger', [
      transition(":enter", [
        query("*", [
          style({ opacity: 0, transform: "translateX(-50px)" }),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            )
          ])
        ])
      ])
    ])
  ]
})
export class BannerComponent implements OnInit {
  backgroundImage: string;
  

  constructor(
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void { 
    this.updateBackgroundImage();
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateBackgroundImage();
  }

  private updateBackgroundImage() {
    if (window.innerWidth <= 768) { // Puedes ajustar el tamaño según necesites
      this.backgroundImage = '../../../../assets/images/eliziaO.png';
    } else {
      this.backgroundImage = '../../../../assets/images/elizia.png';
    }
  }

}
