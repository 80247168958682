<section class="section banner" id="inicio">
    <div [@bannerTrigger] class="container" >
        <div class="section-box-banner" >
            <div class="content" [style.background-image]="'url(' + backgroundImage + ')'" style="background-size: contain; background-position: right; background-repeat: no-repeat;">
                <!-- Tu contenido aquí -->
            

                <div>
                    <!-- <h1>Revoluciona tu empresa y la experiencia de tus clientes con aplicaciones web inteligentes</h1> -->
                    <h1>{{"Banner.Pretitle" | translate}}</h1>
                </div>
                <div class='banner-title'>
                    <h2>ELIZIA</h2>
                    <h3>Atención al Cliente 24/7</h3>
                </div>
                <div class='banner-description'>
                    <p [innerHTML]='"Banner.Description" | translate' class="mt-4"></p>
                </div>
            </div>
            <div class="div-btn-banner">
                <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "banner", "chatai")' href="/ai" target="_black" class="main-btn">
                    {{"Banner.ActionBtn" | translate}}
                </a>
            </div>
        </div>
    </div>
</section>
