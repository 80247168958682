<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-md-block">
            <!-- <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_github", "footer", "web")' href="https://wa.me/59172771369?text= Hola%20vi%20tu%20pagina%20web,%20Me%20interesa%20contactarte" target='_blank'>
                    <i class="fab fa-message-bot"></i>
                </a>
            </li> -->
            <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_linkedin", "footer", "linkedin")' href="/ai" target='_blank'>
                    <i class="fa fa-headset"></i>
                </a>
            </li>
            <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_github", "footer", "wp")' href="https://wa.me/59172771369?text= Hola%20vi%20tu%20pagina%20web,%20Me%20interesa%20contactarte" target='_blank'>
                    <i class="fab fa-whatsapp"></i>
                </a>
            </li>
            <!-- <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_github", "footer", "github")' href="tel:72771369" target='_blank'>
                    <i class="fa fa-phone"></i>
                </a>
            </li> -->
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "footer", "email")' href="mailto:demianccs@gmail.com" target='_blank'>demianccs&#64;gmail.com </a>
        </div>
    </div>

    <div class="footer-credits text-center" >
        <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio_demianolmedo", "footer", "click")' href="https://github.com/demianolmedo" target="_blank" rel="nofollow noopener noreferrer">
            <div>Adaptado by Demian Olmedo</div><br>
        </a>
        <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio_jose", "footer", "click")' href="https://github.com/andresjosehr/andresjosehr-portfolio" target="_blank" rel="nofollow noopener noreferrer">
            <div>Basado en la plantilla diseñada by Jose Andres</div>
        </a>
        <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio_brittany", "footer", "click")' href="https://github.com/bchiang7/v4" target="_blank" rel="nofollow noopener noreferrer">
            <div class="mt-2">Designed by Brittany Chiang</div>
        </a>
    </div>

</footer>